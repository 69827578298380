<template>
  <v-card>
      <v-card-text>

    <div class="prev">
      <img
        :src="$store.state.playlist.previewAds.link"
        alt=""
        v-if="$store.state.playlist.previewAds.type == 'Imagem'"
      />
      <video
        v-else-if="$store.state.playlist.previewAds.type == 'Vídeo' && $store.state.playlist.previewAds.link"
        :src="$store.state.playlist.previewAds.link"
        autoplay
        muted
      ></video>
      <Qr :color="$store.state.playlist.preview.color" v-if="$store.state.playlist.previewAds.showQr" />
    </div>
      </v-card-text>
  </v-card>
</template>

<script>
import Qr from "./Qr.vue"
export default {
  components:{Qr},
  name: "PreviewsAds",
  props: ["anuncio"],
};
</script>

<style scoped>
.prev {
    position: relative;
  width: 1080px;
  height: 1920px;
  zoom: .3;
}
video,
img {
  width: 1080px;
  height: 1920px;
}
.imgPrev{
    position:absolute;
    bottom: 200px;
    right: 100px;
    background: #fff;    
    width: 280px;
    height: 280px;
    border-radius: 50px;
    border: solid 10px #000;
    overflow: hidden;
    text-align: center;
}
.imgPrev img{
    
width: 180px;
height: 180px;
bottom: 0;
}
.leiamais{
  font-family: arial;
  text-align: center;
padding-bottom: 20px;
  font-weight: 900;
  font-size: 25pt;
  font-family: monospace;
}
</style>