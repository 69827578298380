var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-y-auto",attrs:{"height":"670"}},[_c('v-card-title',[_vm._v(" Playlist "),_c('v-spacer'),_vm._v(" "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error","x-small":""},on:{"click":_vm.abort}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"success","x-small":"","disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(" Salvar ")]),_c('v-btn',{staticClass:"mr-4",attrs:{"x-small":"","color":"primary"},on:{"click":_vm.copy}},[_vm._v("Cópia")])],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Nome da playlist","rules":[
          function (v) { return v.length > 0 ||
            'Preencha um nome para esta playlist ex: playlist do dia 01/01/2021'; } ],"required":"","small":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-select',{attrs:{"label":"Tipo","items":['Regiões', 'Totens', 'Padrão'],"rules":[function (v) { return v.length > 0 || 'Tipo é obrigatório'; }]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('v-card-text',[_c('div',{staticClass:"font-weight-bold ml-8 mb-2"},[_vm._v(" Linha do tempo ~ "+_vm._s(_vm.$store.state.playlist.itemsSelected.reduce( function (acc, item) { return (acc += item.time); }, 0 ))+" segundos ")]),_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_c('drop-list',{staticClass:"item-action",attrs:{"items":_vm.$store.state.playlist.itemsSelected},on:{"insert":_vm.onInsert,"reorder":_vm.reorder},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('drag',{key:item._id,staticClass:"item"},[(
                item.text == 1 &&
                _vm.$store.state.playlist.itemsSelected.length == 1
              )?_c('div',{staticStyle:{"height":"200px"}},[_vm._v(" Arraste o ítem ao lado ")]):_vm._e(),(item.text != 1)?_c('timeline-item',{attrs:{"item":item}}):_vm._e()],1)]}},{key:"feedback",fn:function(ref){
              var data = ref.data;
return [_c('div',{key:data._id,staticClass:"item feedback"},[_c('div',{staticClass:"icon-action",style:("background: #dddddd")},[_c('v-icon',{staticClass:"material-icons"},[_vm._v(_vm._s(data.icon)+" ")])],1),_c('div',{staticClass:"baloon"},[_c('div',{staticClass:"box-action"},[_c('div',{staticClass:"text-truncate title-action"},[_vm._v(" "+_vm._s(data.text)+" ")])]),_c('div',{staticClass:"pointer"})])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }