<template>
  <v-timeline-item :color="item.color" :icon="item.itemType == 'Anúncio' ? 'mdi-currency-usd' : 'mdi-newspaper-plus'">
    <v-card :color="item.color" dark>
<v-card-text @click="preview(item)">
         <v-btn class="remove" @click="remove(item._id)" x-small icon><v-icon dark>mdi-trash-can-outline</v-icon></v-btn>
         <div><b>Nome:</b> {{ item.name || item.link }}</div>

          <div><b>Parceiro:</b> {{ item.user.name }}</div>

          <div>
            <b>Início:</b> {{ new Date(item.startDate).toLocaleDateString() }}
          </div>

          <div>
            <b>Término:</b> {{ new Date(item.endDate).toLocaleDateString() }}
          </div>

          <div>
            <b>Duração na tela:</b> {{item.time}} segundos
          </div>

       </v-card-text>

      <v-card-text class="white text--primary" v-if="item.itemType=='Notícia'">
        <p>{{ item.text }}</p>
      </v-card-text>
    </v-card>
  </v-timeline-item>
</template>

<script>
import _ from 'lodash'
export default {
  props: ["item"],
  methods:{
    preview(item){
      if(item.itemType == 'Anúncio'){
        this.$store.state.playlist.type = 'Anúncios'
        this.$store.state.playlist.previewAds = item
      }else{
        this.$store.state.playlist.type = 'Notícias'
        this.$store.state.playlist.preview = item;
      }
      this.$store.state.templates.items = item.templates
      this.$store.state.news = item
            
        },
    remove(id){
      const propName = this.item.itemType == 'Notícia' ? 'itemsNews' : 'itemsAds'
      const index = _.findIndex(this.$store.state.playlist.itemsSelected, {
        _id: id,
      });
      this.$store.state.playlist[propName].push(this.$store.state.playlist.itemsSelected.splice(index, 1)[0])
    }
  }
};
</script>
<style scoped>
.remove{
  position: absolute;
  right: 8px;
  top: 8px;
}
.white{
  padding-bottom: 1px;
}
</style>