<template>
  <v-card class="overflow-y-auto" height="670">
    <v-card-title>
      Playlist <v-spacer></v-spacer> <v-spacer></v-spacer>
      <v-btn color="error" class="mr-4" x-small @click="abort"> Cancelar </v-btn>
      <v-btn
        color="success"
        class="mr-4"
        x-small
        @click="save"
        :disabled="!valid"
      >
        Salvar
      </v-btn>
      <v-btn x-small color="primary" class="mr-4" @click="copy">Cópia</v-btn>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          outlined
          label="Nome da playlist"
          v-model="name"
          :rules="[
            (v) =>
              v.length > 0 ||
              'Preencha um nome para esta playlist ex: playlist do dia 01/01/2021',
          ]"
          required
          small
        ></v-text-field>

        <v-select
          label="Tipo"
          :items="['Regiões', 'Totens', 'Padrão']"
          v-model="type"
          :rules="[(v) => v.length > 0 || 'Tipo é obrigatório']"
        ></v-select>
      </v-form>
    </v-card-text>
    <v-card-text>
      <div class="font-weight-bold ml-8 mb-2">
        Linha do tempo ~
        {{
          $store.state.playlist.itemsSelected.reduce(
            (acc, item) => (acc += item.time),
            0
          )
        }}
        segundos
      </div>

      <v-timeline align-top dense>
        <drop-list
          :items="$store.state.playlist.itemsSelected"
          class="item-action"
          @insert="onInsert"
          @reorder="reorder"
        >
          <template v-slot:item="{ item }">
            <drag class="item" :key="item._id">
              <div
                v-if="
                  item.text == 1 &&
                  $store.state.playlist.itemsSelected.length == 1
                "
                style="height: 200px"
              >
                Arraste o ítem ao lado
              </div>
              <timeline-item v-if="item.text != 1" :item="item">
                <!-- <template v-slot:activator="{ on }">fasdafsdf
                      <v-icon v-on="on">mdi-alert-box</v-icon>
                    </template> -->
              </timeline-item>
            </drag>
          </template>
          <template v-slot:feedback="{ data }">
            <div class="item feedback" :key="data._id">
              <div :style="`background: #dddddd`" class="icon-action">
                <v-icon class="material-icons">{{ data.icon }} </v-icon>
              </div>
              <div class="baloon">
                <div class="box-action">
                  <div class="text-truncate title-action">
                    {{ data.text }}
                  </div>
                </div>
                <div class="pointer"></div>
              </div>
            </div>
          </template>
        </drop-list>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { DropList, Drag } from "vue-easy-dnd";
import _ from "lodash";
import TimelineItem from "./TimelineNew";
import { getPlaylistById, updatePlaylist, getNewsActives, createPlaylis } from "../../../services/playlists";
export default {
  components: { DropList, Drag, TimelineItem },
  data: () => ({
    messages: [
      {
        from: "You",
        message: `Sure, I'll see you later.`,
        time: "10:42am",
        color: "deep-purple lighten-1",
      },
      {
        from: "John Doe",
        message: "Yeah, sure. Does 1:00pm work?",
        time: "10:37am",
        color: "green",
      },
      {
        from: "You",
        message: "Did you still want to grab lunch today?",
        time: "9:47am",
        color: "deep-purple lighten-1",
      },
    ],
    name: "",
    valid: false,
    fonteNoticia: "",
    tituloMateria: "",
    fileLink: "",
    templateImg: "",
    templateImgLogo: "",
    creditosImagem: "",
    templateBackgroundColor: "blue",
    type: "",
    time: 0,
  }),
  methods: {
    copy(){
      this.$store.state.loading = true
      this.name = this.name + ' - CÓPIA'
      this.$store.state.playlist.editedPlaylist.name = this.name
      this.$store.state.playlist.newPlaylist = Object.assign({},this.$store.state.playlist.editedPlaylist)
      delete this.$store.state.playlist.newPlaylist._id
      createPlaylis().then((data) => {
        this.$store.state.playlist.editedPlaylist = data
        this.$store.state.loading = false
        this.$store.commit('success', 'Playlist replicada!')
        this.$router.push('/playlists/edit/'+data._id)
      }).catch(err => {
        this.$store.commit('failed', "ERRO: " + err)
        this.$store.state.loading = false
      })
    },
    save() {
      const isValid = this.$refs.form.validate();
      if (!isValid) return;
      const items = this.$store.state.playlist.itemsSelected.filter(
        (data) => data.text != 1
      );

      if (!items.length)
        return this.$store.commit(
          "failed",
          "Nenhum ítem selecionado para esta playlist"
        );
      this.$store.state.playlist.editedPlaylist = {
        ...this.$store.state.playlist.editedPlaylist,
        type: this.type,
        items,
        user: this.$store.state.user,
        name: this.name,
      };
      this.$store.state.loading = true;
      updatePlaylist(this.$store.state.playlist.editedPlaylist._id)
        .then(() => {
          this.$store.commit("success", "Playlist atualizada com sucesso!");
          this.$store.state.loading = false;
          this.$router.push("/playlists/list");
        })
        .catch((err) => this.$store.commit("failed", "ERRO: " + err));
        this.$store.state.loading = false;
    },
    abort() {
      if (confirm("Descartar playlist?")) {
        this.$router.push("/playlists/list");
      }
    },
    reorder(e) {
      e.apply(this.$store.state.playlist.itemsSelected);
    },
    onInsert(event) {
      const propName =
        event.data.itemType == "Notícia" ? "itemsNews" : "itemsAds";
      this.$store.state.playlist.itemsSelected.splice(
        event.index,
        0,
        event.data
      );
      const index = _.findIndex(this.$store.state.playlist[propName], {
        _id: event.data._id,
      });
      this.$store.state.playlist[propName].splice(index, 1);
    },
  },
  async mounted(){
    this.$store.state.loading = true
    await getPlaylistById(this.$route.params.id).catch(err => this.$state.commit('failed', 'ERRO: '+err))
    await getNewsActives()

    this.$store.state.loading = false
    this.type = this.$store.state.playlist.editedPlaylist.type
    this.name = this.$store.state.playlist.editedPlaylist.name    
  },
  watch: {
    "$store.state.playlist.itemsSelected"(items){
      const timeline = items.map(item => Object.assign({}, item)).filter(item => !!item.createdAt)
      const names = timeline.reduce((acc, item) => {
        acc[item.user.name.slice(0,4)] = acc[item.user.name.slice(0,4)] ? acc[item.user.name.slice(0,4)] + 1: 1
        return acc
      }, {})
      let name = ''
      Object.keys(names).forEach(key => {
        name += key + "_" + names[key] + '_'
      })
      name = name.split('')
      name.pop()
      this.name = name.join('')
      
    }
  }
};
</script>